import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { Section } from '../components/common/index.js';
import {
  Header,
  FlexGallery,
  FlexGalleryWrapper
} from '../components/layout/index.js';

const BlogPage = ({ data }) => (
  <>
    <Helmet
      title="Australia and New Zealand backpacker travel guide"
      meta={[
        {
          name: 'description',
          content:
            'Lastest tips and advice on backpacking and travel around Australia, New Zealand and the world'
        }
      ]}
    />
    <Header
      backgroundImage={data.file.childImageSharp.fluid}
      pageTitle="Seeking Travel Inspiration?"
      tagline="Checkout our guide to backpacking in Australia and New Zealand"
      propertyName="Base Magnetic Island"
      caption="Sunset Walking Tour"
    />
    <Section lightBackground>
      <h2>Featured Articles</h2>
      <FlexGalleryWrapper>
        {data.featured.edges.map(({ node: blog }) => (
          <FlexGallery
            title={blog.title}
            fluid={blog.featuredImage.fluid}
            key={blog.id}
            alt={blog.featuredImage.alt}
            // index={index}
            url={`/${blog.destination.slug}/${blog.slug}/`}
            id={blog.id}
          />
        ))}
      </FlexGalleryWrapper>
      <br />
      <br /> <h2>Latest Articles</h2>
      <FlexGalleryWrapper>
        {data.recent.edges.map(({ node: blog }) => (
          <FlexGallery
            title={blog.title}
            fluid={blog.featuredImage.fluid}
            key={blog.id}
            alt={blog.featuredImage.alt}
            // index={index}
            url={`/${blog.destination.slug}/${blog.slug}/`}
            id={blog.id}
          />
        ))}
      </FlexGalleryWrapper>
    </Section>
  </>
);

export default BlogPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "magnetic-island4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featured: allDatoCmsBlog(
      filter: { locale: { eq: "en" }, featuredPost: { eq: "Yes" } }
    ) {
      edges {
        node {
          id
          title

          slug
          destination {
            slug
          }
          featuredImage {
            alt
            fluid(
              maxWidth: 300
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              } 
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    recent: allDatoCmsBlog(
      filter: { locale: { eq: "en" } }
      sort: { fields: [meta___updatedAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          destination {
            slug
          }
          featuredImage {
            alt
            fluid(
              maxWidth: 300
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
